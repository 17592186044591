import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import moment from 'moment'

import Typography from '@material-ui/core/Typography'

import { ChildrenProp } from '../../../interfaces/props'
import { buildNumber } from '../../../utils/constants'
import { capitalize } from '../../../utils/strings'
import { SortMode } from '../../../utils/constants/enum'

import useStyles from './useStyles'
import { useSelector } from 'react-redux';
import {
  getCurrentCapacitySlot,
  getIsCurrentCapacitySlotClosed,
  getOpenCapacitySlots,
} from '../../../store/entities/sortMode';
import { DropdownMenuItem, DropdownMenu, Button, Callout, Tooltip, DropdownMenuAction } from '../../Base/.raw';
import { CapacitySlot } from '../../../interfaces/models/capacitySlot';
import Icon from '../../Base/.raw/Icon';
import { PlainEventHandler } from '../../../interfaces/types';
import { mapServerText } from '../../../utils/strings/mapServerText';
import { Hidden } from '@material-ui/core';

export type CurrentModeProps = ChildrenProp & {
  mode: SortMode,
  onCapacitySlotSwitch: (capacitySlotDate: string, networkType: string) => void,
  onCloseCapacitySlot: PlainEventHandler
  selectedCapacitySlot?:  {networkType?: string, capacitySlotDate?: string}
}

const CurrentMode = ({
  mode, children, onCapacitySlotSwitch, onCloseCapacitySlot, selectedCapacitySlot,
}: CurrentModeProps) => {
  const capacitySlots = useSelector(getOpenCapacitySlots)
  const { networkType, capacitySlotDate } = useSelector(getCurrentCapacitySlot)
  const isCurrentCapacitySlotClosed = useSelector(getIsCurrentCapacitySlotClosed)
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false)

  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    setIsDropdownDisabled(false)
  },        [selectedCapacitySlot])

  const switchCapacitySlot = useCallback((value: string) => () => {
    const { capacitySlotDate, networkType } = JSON.parse(value)
    onCapacitySlotSwitch(capacitySlotDate, networkType)
    setIsDropdownDisabled(true)
  },                                     [onCapacitySlotSwitch])

  const isSwitchButtonDisabled = capacitySlots.findIndex((capacitySlot) => {
    if (moment(capacitySlot.date).format('YYYY-MM-DD') === capacitySlotDate && capacitySlot.networkType !== networkType) {
      return capacitySlot.isClosed
    }
    return false
  }) > -1

  const isCapacitySlotToday = () => {
    return moment(capacitySlotDate).isSame(moment().format('YYYY-MM-DD'), 'day');
  }

  const currentCapacitySlotText = (
    <div className={classes.currentCapacitySlotText}>
      <span>{`${moment(capacitySlotDate).format('MMM DD')} - ${capitalize(mapServerText(networkType))} Capacity`}</span>
      <Button variant='primary-reverse' disabled={isSwitchButtonDisabled} onClick={switchCapacitySlot(JSON.stringify({ capacitySlotDate, networkType: networkType && networkType.toUpperCase() === 'DAY' ? 'NIGHT' : 'DAY' }))}>
        <span className={classes.currentCapacitySlotTextLabel}>
          {`Switch to ${(networkType && networkType.toUpperCase() === 'DAY' ? 'Evening' : 'Day')}`}
        </span>
        <span className={classes.currentCapacitySlotTextIcon}>
          <Icon name={networkType && networkType.toUpperCase() === 'DAY' ? 'switch-night' : 'switch-day'} />
        </span>
      </Button>
    </div>
  )

  const capacityByDate  = groupBy(capacitySlots, (capacitySlot: CapacitySlot) => moment(capacitySlot.date).format('MMM DD'))

  return (
    <div className={clsx(classes.container, get(classes, mode))}>
      {
        isCurrentCapacitySlotClosed && (
          <Hidden mdUp={true}>
            <div>
              <Callout variant='warning' icon='warning' className={classes.callout}>
                <span>Hey There!</span>
                <span>This capacity is already closed.</span>
              </Callout>
            </div>
          </Hidden>
        )
      }
      <div className={clsx(classes.capacitySwitchContainer, mode !== SortMode.region && classes.hidden)}>
        <div>
          <div className={classes.capacitySwitchIcon}>
            <Tooltip text='This slot is currently active.' placement='bottom' offset={[30, -8]}>
              <Icon name={isCurrentCapacitySlotClosed ? 'warning' : 'calendar-check'}/>
            </Tooltip>
          </div>
          <DropdownMenu
            className={classes.capacitySwitch}
            disabled={!capacitySlots.length || isDropdownDisabled}
          >
            {useMemo(
              () =>
                Object.keys(capacityByDate).map((date: string, capacityDateIndex) => {
                  return (
                    <DropdownMenuItem key={`capacity-slots-${capacityDateIndex}`} className={classes.capacitySwitchMenuItem}>
                      <div>
                        <span>{date}</span>
                        <div className={classes.capacitySlotButtonSelector}>
                          {
                            (get(capacityByDate, date, []) as CapacitySlot[]).map((capacitySlot: CapacitySlot, i) => {
                              const value = JSON.stringify({ networkType: capacitySlot.networkType, capacitySlotDate: capacitySlot.date })
                              const isActiveCapacitySlot = moment(capacitySlot.date).format('YYYY-MM-DD') === capacitySlotDate && capacitySlot.networkType === networkType
                              return (
                                <DropdownMenuAction key={`capacity-slots-button-${capacityDateIndex}-${i}`} disabled={capacitySlot.isClosed  || isActiveCapacitySlot} onClick={switchCapacitySlot(value)}>
                                  <Tooltip disabled={!capacitySlot.isClosed} text='This capacity is already closed.' placement='bottom' offset={[0, -10]}>
                                    <Button disabled={capacitySlot.isClosed || isActiveCapacitySlot} onClick={switchCapacitySlot(value)}>
                                      {capacitySlot.isClosed && (
                                          <Icon name='warning'/>
                                      )}
                                      {capitalize(mapServerText(capacitySlot.networkType))}
                                    </Button>
                                  </Tooltip>
                                </DropdownMenuAction>
                              )
                            })
                          }
                        </div>
                      </div>
                    </DropdownMenuItem>
                  )
                }),
              [capacityByDate, capacitySlotDate, networkType, classes.capacitySwitchMenuItem, classes.capacitySlotButtonSelector, switchCapacitySlot]
            )}
          </DropdownMenu>
          <DropdownMenu
            className={clsx(classes.capacitySwitch, classes.capacitySwitchBottom)}
            disabled={!capacitySlots.length || isDropdownDisabled}
            placeholder={currentCapacitySlotText}
            placement='top'
          >
            {useMemo(
              () =>
                Object.keys(capacityByDate).map((date: string, capacityDateIndex) => {
                  return (
                    <DropdownMenuItem key={`capacity-slots-${capacityDateIndex}`} className={classes.capacitySwitchMenuItem}>
                      <div>
                        <span>{date}</span>
                        <div className={classes.capacitySlotButtonSelector}>
                          {
                            (get(capacityByDate, date, []) as CapacitySlot[]).map((capacitySlot: CapacitySlot, i) => {
                              const value = JSON.stringify({ networkType: capacitySlot.networkType, capacitySlotDate: capacitySlot.date })
                              const isActiveCapacitySlot = moment(capacitySlot.date).format('YYYY-MM-DD') === capacitySlotDate && capacitySlot.networkType === networkType
                              return (
                                <DropdownMenuAction key={`capacity-slots-button-${capacityDateIndex}-${i}`} disabled={capacitySlot.isClosed || isActiveCapacitySlot} onClick={switchCapacitySlot(value)}>
                                  <Tooltip disabled={!capacitySlot.isClosed} text='This capacity is already closed.' placement='bottom' offset={[0, -10]}>
                                    <Button disabled={capacitySlot.isClosed || isActiveCapacitySlot} onClick={switchCapacitySlot(value)}>
                                      {capacitySlot.isClosed && (
                                          <Icon name='warning'/>
                                      )}
                                      {capitalize(mapServerText(capacitySlot.networkType))}
                                    </Button>
                                  </Tooltip>
                                </DropdownMenuAction>
                              )
                            })
                          }
                        </div>
                      </div>
                    </DropdownMenuItem>
                  )
                }),
              [capacityByDate, capacitySlotDate, networkType, classes.capacitySwitchMenuItem, classes.capacitySlotButtonSelector, switchCapacitySlot]
            )}
          </DropdownMenu>
          {
            isCurrentCapacitySlotClosed && (
              <Hidden mdDown={true}>
                <div>
                  <Callout variant='warning' icon='warning' className={classes.callout}>
                    <span>Hey There!</span>
                    <span>This capacity is already closed.</span>
                  </Callout>
                </div>
              </Hidden>
            )
          }
        </div>
        {
          !isCurrentCapacitySlotClosed && (
              <div className={classes.closeCapacityContainer}>
                <Tooltip disabled={isCapacitySlotToday()} text={t('main.close_capacity_slot_tooltip')} placement="auto" offset={[-50, 0]}>
                  <Button variant='default-warning' onClick={onCloseCapacitySlot} disabled={!isCapacitySlotToday()}><Icon name='warning'/> Close this capacity</Button>
                </Tooltip>
              </div>
          )
        }
      </div>
      <span>
        {mode !== SortMode.unknown && (
          <>
            <Typography
              variant='h4'
              color='textSecondary'
              className={clsx(classes.text, classes.sortingTitle)}
            >
              {t(`main:${mode === SortMode.region ? 'currently_sorting_for' : 'currently_sorting'}`)}
            </Typography>
            <Typography variant='h1' color='textSecondary' className={classes.text}>
              {
                mode === SortMode.region
                  ? moment(capacitySlotDate).format('DD-MM-YYYY')
                  : mode === SortMode.missedConnection ? t('main:missed_connection_mode')
                  : t('main:center_mode')
              }
            </Typography>
          </>
        )}

        {children && (
          <div className={classes.childContainer}>
            {children}
          </div>
        )}
      </span>

      <div className={classes.build}>
        <Typography variant='caption' color='inherit'>
          {buildNumber}
        </Typography>
      </div>
    </div>
  )
}

CurrentMode.propTypes = {
  children: PropTypes.node,
  mode: PropTypes.oneOf(Object.values(SortMode)),
  onCapacitySlotSwitch: PropTypes.func,
  onCloseCapacitySlot: PropTypes.func,
}

CurrentMode.defaultProps = {
  children: null,
  mode: SortMode.region,
  onCapacitySlotSwitch: () => {},
  onCloseCapacitySlot: () => {},
}

export default CurrentMode
